import { Divider, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { INVOICE_CONFLICT_TYPE, PriceType, RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH } from "../../../util/Constants";
import {getRow, renderFeatures, renderImage, renderVolumeType} from "../../../util/TableUtil";
import { formatPrice } from "../../../util/Util";
import { GeneralContext } from "../../contexts/GeneralContext";
import ArticleDetail from "../../share/ArticleDetail";
import {getActiveOperation} from "../../../services/OperationService";

class InvoiceArticleDetails extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            articleForDetailView: null
        }
    }

    generalDetails = (invoiceItem, contractItem) => {
        const currency = " " + getActiveOperation(this.context).activeProject.currency;
        let detailsList = [
            ["cockpit.invoices.articleDetails.invoiceName", invoiceItem.articleName]
        ];
        if (invoiceItem.articleNumber) {
            detailsList.push(["cockpit.invoices.articleDetails.articleNumber", invoiceItem.articleNumber]);
        }
        if (contractItem?.item) {
            detailsList.push(["cockpit.invoices.articleDetails.contractPrice", formatPrice(contractItem.price) + currency]);
            detailsList.push(["cockpit.invoices.articleDetails.contractAmount", contractItem.item.amount ?? contractItem.item.count ?? contractItem.item.quantity ?? 0]);
        }
        if (invoiceItem.conflictType !== INVOICE_CONFLICT_TYPE.MISSING_ARTICLE) {
            detailsList.push(["cockpit.invoices.articleDetails.invoicePrice", formatPrice(invoiceItem.price) + currency]);
            detailsList.push(["cockpit.invoices.articleDetails.invoiceAmount", invoiceItem.amount ?? 0]);
        }
        return detailsList.map(([textId, content]) => getRow(textId, content));
    }

    specificDetails = (contractItem) => {
        switch (contractItem?.type) {
            case PriceType.ARTICLE_QUANTITY:
            case PriceType.ARTICLE_WEEKLY:
                return this.rentalLinenDetails(contractItem);
            case PriceType.CUSTOMER_ARTICLE:
                return this.customerArticleDetails(contractItem);
            case PriceType.RESIDENTS_LAUNDRY:
                return this.residentsLaundryDetails(contractItem);
            case PriceType.OPERATING_RESOURCE:
                return this.operatingResourceDetails(contractItem);
            case PriceType.SPECIAL_SERVICE:
                return this.specialServiceDetails(contractItem);
            default:
                return <div/>;
        }
    }

    rentalLinenDetails = (contractItem) => {
        const article = contractItem.item.articleVo;
        return [
            ["entities.article.category", this.props.intl.formatMessage({id: "constants.Category." + article.category})],
            ["entities.article.subCategory", this.props.intl.formatMessage({id: "constants.SubCategory." + article.subcategory})],
            ["entities.article.articleCategory", this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})],
            ["articleOffsetting.offsetting.offsetting", 
                this.props.intl.formatMessage({id: "constants.ArticleOffsetting." + contractItem.item.offsetting?.articleCategoryOffsetting})],
            ["entities.article.image", renderImage(article, () => this.showArticleDetails(article))],
            ["entities.article.description", article.description],
            ["entities.article.alternateDescription", contractItem.alternateDescription]
        ].map(([textId, content]) => getRow(textId, content));
    }

    showArticleDetails = (article) => {
        this.setState({articleForDetailView: article});
    }

    hideArticleDetails = () => {
        this.setState({articleForDetailView: null});
    }

    customerArticleDetails = (contractItem) => {
        const customerArticle = contractItem.item;
        return [
            ["customerArticle.table.header.description", customerArticle.description],
            ["customerArticle.table.header.volumeType", renderVolumeType(customerArticle, this.props.intl)],
            ["customerArticle.create.features.title", renderFeatures(customerArticle.features, this.props.intl, "constants.ArticleFeatureType.")],
        ].map(([textId, content]) => getRow(textId, content));
    }

    residentsLaundryDetails = (contractItem) => {
        const laundry = contractItem.item;
        const cleanType = laundry.cleanType;
        let tableTitle;
        if (cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH) {
            tableTitle = "wash";
        } else {
            tableTitle = "chemical";
        }
        return [
            ["residentsLaundry.pricesheet.table.cleanType", this.props.intl.formatMessage({id: "residentsLaundry.table.title." + tableTitle})],
            ["residentsLaundry.article.name", laundry.name],
            ["articleOffsetting.offsetting.offsetting", 
                this.props.intl.formatMessage({id: "constants.ArticleOffsetting.DELIVERED_QUANTITY"})]
        ].map(([textId, content]) => getRow(textId, content));
    }

    operatingResourceDetails = (contractItem) => {
        const resource = contractItem.item;
        return [
            ["operatingResources.table.name", resource.name],
            ["articleOffsetting.offsetting.offsetting", 
                this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + resource.resourceOffsetting})]
        ].map(([textId, content]) => getRow(textId, content));
    }

    specialServiceDetails = (contractItem) => {
        const specialService = contractItem.item;
        return [
            ["specialServices.table.header.name", specialService.name],
            ["specialServices.table.header.text", specialService.text],
            ["articleOffsetting.offsetting.offsetting", 
                this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + specialService.offsetting})]
        ].map(([textId, content]) => getRow(textId, content));
    }

    render() {

        const { contractItem, invoiceItem } = this.props;

        if (!invoiceItem) {
            return (<div/>);
        }

        return (
            <div>
                {this.state.articleForDetailView && <ArticleDetail 
                    article={this.state.articleForDetailView}
                    showDialog={!!this.state.articleForDetailView}
                    handleDialogClose={this.hideArticleDetails}
                />}

                <Grid container alignItems="flex-start" style={{width: 500, padding: 25, ...this.props.style}}>

                    {!!contractItem && <>
                    
                        {getRow(
                            "cockpit.invoices.articleDetails.category", 
                            this.props.intl.formatMessage({id: "constants.PRICE_TYPE." + contractItem.type}))}

                        <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                            <Divider orientation="horizontal"/>
                        </Grid>

                        {this.specificDetails(contractItem)}

                        <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                            <Divider orientation="horizontal"/>
                        </Grid>
                    
                    </>}

                    {this.generalDetails(invoiceItem, contractItem)}

                </Grid>
            </div>
        );
    }
}

export default injectIntl(InvoiceArticleDetails);
