import React, {Component} from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';

import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {texisionWarningOrange} from '../../../util/ColorTheme';
import {isUser, isAdmin, Role} from '../../../services/UserService';

import {FormattedMessage, injectIntl} from 'react-intl';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {GeneralContext} from "../../contexts/GeneralContext";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "react-select";
import {COUNTRIES} from "../../../util/Constants";


class UserManagementDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state ={
            id: null,
            version: null,
            title: null,
            email: "",
            firstname: "",
            lastname: "",
            company: "",
            role: "",
            jobTitle: "",
            streetAndNumber: "", 
            city: "",
            zipCode: "",
            phoneNumber: "", 
            vatId: "",
            isSaving: false,
            assignedOperationIds: []
        }
    }

    componentDidMount() {
        this.initUser();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.initUser();
        }
    }

    initUser = () => {
        let user = this.props.user;
        if (!user) {
            this.setState({
                id: null,
                version: null,
                title: null,
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                role: "",
                jobTitle: "",
                streetAndNumber: "",
                zipCode: "",
                city: "",
                phoneNumber: "",
                vatId: "",
                assignedOperationIds: []
            });
        } else {
            this.setState({
                id: user.id,
                version: user.version,
                title: user.title,
                email: user.email,
                firstname: user.firstName,
                lastname: user.lastName,
                company: user.company,
                role: user.role,
                jobTitle: user.jobTitle,
                streetAndNumber: user.streetAndNumber,
                zipCode: user.zipCode,
                city: user.city,
                phoneNumber: user.phoneNumber,
                vatId: user.vatId,
                assignedOperationIds: this.props.allOperations
                    .filter(o => user.operationIds?.includes(o.id)).map(o => o.id)
            });
        }
    }

    handleCloseDialog = () => {
        this.initUser();
        this.props.hideUserDialog();
    }

    validateEmailAddress = () => {
        return this.state.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email);
    }

    validateRequiredFields = () => {
        // bidders shall not be assigned to operations at this moment
        return this.state.email && this.state.role && this.state.company
            && (this.state.assignedOperationIds?.length || [Role.BIDDER, Role.BIDDER_NEWSLETTER, Role.BIDDER_READ_ONLY].includes(this.state.role));

    }

    validateAndSaveUser = async() => {

        if (this.validateEmailAddress() && this.validateRequiredFields()) {

            let user = {
                id: this.state.id,
                version: this.state.version,
                username:  this.state.email.toLowerCase(),
                title:  this.state.title,
                email:  this.state.email,
                firstName:  this.state.firstname,
                lastName:  this.state.lastname,
                company:  this.state.company,
                role:  this.state.role,
                jobTitle:  this.state.jobTitle,
                country: COUNTRIES.DE,
                operationIds:  this.state.assignedOperationIds,
                activated: true,
                password: ""
            }

            if (this.state.role !== Role.ORDERER) {
                user.streetAndNumber = this.state.streetAndNumber;
                user.zipCode = this.state.zipCode;
                user.city = this.state.city;
                user.phoneNumber = this.state.phoneNumber;
                user.vatId = this.state.vatId;
            }

            const responseData = await this.props.onSave(user);
            if (responseData) {
                this.handleCloseDialog();
            }
        }
    }

    handleZipCodeChange = (zipCode) => {
        const zipCodeRegex = /^[0-9]*$/;
        if (zipCode === "" || zipCodeRegex.test(zipCode)) {
            this.setState({zipCode});
        }
    }

    titleInput = ({textId, value, onChange}) => {
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        {this.props.intl.formatMessage({id: textId})}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        id="person-title-id"
                        value={{value: value, label: value ? this.props.intl.formatMessage({id: "constants.Salutation." + value}): ""}}
                        onChange={(e) => onChange(e.value)}
                        variant="outlined"
                        placeholder={""}
                        style={{width: "50%"}}
                        options={this.context.appData.userTitles.map(title => {
                            return {
                                value: title,
                                label: this.props.intl.formatMessage({id: "constants.Salutation." + title})
                            };
                        })}/>
                </Grid>
            </>
        );
    }

    textInput = ({textId, required, validate, value, onChange, maxLength, width, disabled}) => {
        const validInput = validate ? validate() : value;
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        {this.props.intl.formatMessage({id: textId}) + (required ? " *" : "")}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        id={textId}
                        maxLength={maxLength ?? 256}
                        value={value} 
                        variant="outlined"
                        disabled={disabled}
                        style={{
                            width: width ?? "100%", 
                            backgroundColor: (!required || (required && validInput)) ? null : texisionWarningOrange
                        }}
                        onChange={(e) => onChange(e.target.value)}/>
                </Grid>
            </>
        );
    }

    roleInput = ({textId}) => {
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        {this.props.intl.formatMessage({id: textId}) + " *"}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        id="person-role"
                        required
                        value={{
                            value: this.state.role ? this.state.role : "",
                            label: this.state.role ? this.props.intl.formatMessage({id: "constants.UserRole." + this.state.role}) : ""
                        }}
                        onChange={(e) => this.setState({role: e.value})}
                        isDisabled={!!this.props.user}
                        variant="outlined"
                        style={{width: "50%"}}
                        options={[
                            ...[Role.USER, Role.ORDERER].map(title => {
                                return {
                                    value: title,
                                    label: this.props.intl.formatMessage({id: "constants.UserRole." + title})
                                };
                            }),
                            ...(isAdmin(this.context.currentUser)
                                ? [Role.PROJECT_ADMIN, Role.BIDDER_NEWSLETTER, Role.BIDDER_READ_ONLY, Role.BIDDER].map(title => {
                                    return {
                                        value: title,
                                        label: this.props.intl.formatMessage({id: "constants.UserRole." + title})
                                    };
                                })
                                : []
                            )
                        ]}/>
                </Grid>
            </>
        )
    }

    zipCodeAndCityInput = ({textId, zipCode, city}) => {
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        <FormattedMessage id={textId}/>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        id="zipCode"
                        value={zipCode}
                        variant="outlined" 
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.handleZipCodeChange(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        id="city"
                        value={city}
                        variant="outlined" 
                        inputProps={{maxLength: 128}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({city: e.target.value})}/>
                </Grid>
            </>
        );
    }

    countryInput = ({textId}) => {
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        <FormattedMessage id={textId}/>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        disabled
                        value={this.props.intl.formatMessage({id: "constants.country.de"})}
                        variant="outlined" 
                        inputProps={{maxLength: 300}}
                        style={{width: "50%", backgroundColor: "lightgrey"}}/>
                </Grid>
            </>
        );
    }

    assignOperationInput = ({textId}) => {
        return (
            <>
                <Grid item xs={3}>
                    <Typography>
                        {this.props.intl.formatMessage({id: textId}) + " *"}
                    </Typography>
                </Grid>

                <Grid item xs={9}>
                    <Paper elevation={0} style={{maxHeight: 300, overflow: 'auto'}}>
                        <List>
                            {this.props.allOperations.map((value) => {
                                return (
                                    <ListItem
                                        key={value.id}
                                        value={value}
                                        dense
                                        button
                                        onClick={() => this.handleAssignOperations(value)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                color="primary"
                                                key={value}
                                                checked={this.state.assignedOperationIds.includes(value.id)}
                                                edge="start"/>
                                        </ListItemIcon>
                                        <ListItemText id={value.id} primary={value.workingProject?.name ?? value.activeProject?.name}/>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Paper>
                </Grid>
            </>
        );
    }

    handleAssignOperations = (operation) => {
        let assignedOperationIds = this.state.assignedOperationIds;
        if (assignedOperationIds.includes(operation.id)) {
            assignedOperationIds = assignedOperationIds.filter(assignedId => assignedId !== operation.id);
        } else {
            assignedOperationIds.push(operation.id);
        }
        this.setState({assignedOperationIds});
    }

    render() {
        const user = this.context.currentUser;

        return (
            <TexisionDialog
                key={this.props.key}
                open={this.props.showUserDialog}
                hasNoTitle={true}
                style={{}}
                actionId={!this.props.user ? "commons.create.button" : "commons.save.button"}
                cancelId="commons.cancel.button"
                onAction={async() => {
                    this.setState({isSaving: true});
                    await this.validateAndSaveUser();
                    this.setState({isSaving: false});
                }}
                onCancel={() => this.handleCloseDialog()}
                actionDisabled={!this.validateRequiredFields() || !this.validateEmailAddress() || isUser(user) || this.state.isSaving}
                content={
                
                <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}>

                    <Grid item xs={12} style={{paddingBottom: "20px"}}>
                        <Typography variant="h2">
                            <FormattedMessage id="useradmin.createDialog.title"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DialogContentText>
                            <FormattedMessage id="useradmin.createDialog.requiredNotice"/>
                        </DialogContentText>
                    </Grid>

                    {this.roleInput({
                        textId: "useradmin.role"
                    })}

                    {this.textInput({
                        textId: "useradmin.email", 
                        required: true,
                        disabled: !!this.props.user,
                        validate: () => this.validateEmailAddress(), 
                        value: this.state.email, 
                        onChange: (email) => this.setState({email})})
                    }

                    {[Role.USER, Role.ORDERER, Role.PROJECT_ADMIN, Role.ADMIN].includes(this.state.role) && this.assignOperationInput({
                        textId: "useradmin.projectCount"
                    })}

                    {this.textInput({
                        textId: "useradmin.company", 
                        required: true, 
                        value: this.state.company, 
                        onChange: (company) => this.setState({company})})
                    }

                    {this.titleInput({
                        textId: "useradmin.title",
                        value: this.state.title,
                        onChange: (title) => this.setState({title})
                    })}

                    {this.textInput({
                        textId: "useradmin.firstname", 
                        required: true,
                        value: this.state.firstname, 
                        onChange: (firstname) => this.setState({firstname})})
                    }

                    {this.textInput({
                        textId: "useradmin.lastname", 
                        required: true,
                        value: this.state.lastname, 
                        onChange: (lastname) => this.setState({lastname})})
                    }

                    {this.textInput({
                        textId: "useradmin.jobTitle", 
                        required: false, 
                        value: this.state.jobTitle, 
                        onChange: (jobTitle) => this.setState({jobTitle})})
                    }

                    {this.textInput({
                        textId: "useradmin.streetAndNumber",
                        maxLength: 128, 
                        required: false, 
                        value: this.state.streetAndNumber, 
                        onChange: (streetAndNumber) => this.setState({streetAndNumber})})
                    }

                    {this.zipCodeAndCityInput({
                        textId: "useradmin.zipCodeAndCity",
                        zipCode: this.state.zipCode,
                        city: this.state.city
                    })}

                    {this.countryInput({
                        textId: "useradmin.country"
                    })}

                    {this.textInput({
                        textId: "useradmin.phoneNumber",
                        width: "50%",
                        maxLength: 64,
                        required: false, 
                        value: this.state.phoneNumber, 
                        onChange: (phoneNumber) => this.setState({phoneNumber})})
                    }

                    {this.textInput({
                        textId: "useradmin.vatId",
                        width: "50%",
                        maxLength: 64,
                        required: false, 
                        value: this.state.vatId, 
                        onChange: (vatId) => this.setState({vatId})})
                    }
                
                </Grid>}/>
        )
    }
}

export default injectIntl(UserManagementDialog);
