import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Grid, Typography} from "@material-ui/core";
import PublishBidderSelection from "./PublishBidderSelection";
import {Card, CardContent} from "@mui/material";
import texisionLogo from "../../../resources/texision_logo.svg";
import {LOGIN_ROUTE} from "../../../util/Constants";
import {Link} from "react-router-dom";

class PublicLaundryRadar extends Component {

    render() {
        return (
            <Grid container style={{justifyContent: "space-between", height: "100vh"}}>
                <Grid item xs={12}>
                    <Card style={{margin: "3%", padding: "20px", minWidth: 300}}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h1" style={{textAlign: "center"}}>
                                        <FormattedMessage id="laundryRadar.title"/>
                                    </Typography>

                                    <Typography variant="subtitle1" style={{textAlign: "center"}}>
                                        <FormattedMessage id="laundryRadar.subtitle"/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <PublishBidderSelection
                                        readOnly={true}
                                        selectedBidders={[]}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Link to={{pathname: LOGIN_ROUTE}} >
                        <img
                            src={texisionLogo}
                            style={{maxWidth: "10rem", width: "100%"}}
                            alt="Texision"/>
                    </Link>
                    <div
                        style={{fontSize: '15px', fontWeight: "bold", marginBottom: "40px"}}>
                        <FormattedMessage id="texision.slogan"/>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(injectIntl(PublicLaundryRadar));
